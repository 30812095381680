<template>
  <!-- 欄位選擇器 -->
  <div class="d-inline-block me-2">
    <p>選擇欄位</p>
    <MultiSelect
      v-model="selectedFields"
      :options="fields"
      optionLabel="name"
      placeholder="未選擇欄位"
      style="width: 150px"
      @change="updatePrefer"
    ></MultiSelect>
  </div>
</template>

<script>
// components
import MultiSelect from 'primevue/multiselect';

export default {
  components: { MultiSelect },
  props: {
    // 針對哪個表格
    whitchOneTable: {
      type: String,
      default: null
    },
    // 那個表格預設欄位偏好
    D4FieldFilter: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      selectedFields: null,
      fields: []
    }
  },
  watch: {
    whitchOneTable: {
      handler(val) {
        // 先檢查表格欄位的偏好設定是否傳進來了
        this.checkD4Prefer()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 檢查父層的偏好是否傳進來了
    checkD4Prefer() {
      const checkPoint = setInterval(() => {
        if (this.D4FieldFilter.length > 0) {
          clearInterval(checkPoint)
          this.checkFieldsData()
        }
      }, 500)
    },
    // 檢查目前為哪一個表格要的欄位 (不能路由判斷，因為有可能同一個路由下同時有兩個表格存在)
    checkFieldsData() {
      // 在接著針對不同表格塞入預設的欄位偏好
      console.log(`目前表格: ${this.whitchOneTable}`)
      this.fields = []
      this.selectedFields = []
      let currentField = []
      switch (this.whitchOneTable) {
        case 'merchandise':
          currentField = this.sortoutMerchandiseFields()
          break;
        case 'merchOrder':
          currentField = this.sortoutMerchOrderFields()
          break;
        case 'stock':
          currentField = this.sortoutStockFields()
          break;
        case 'participant':
          currentField = this.sortoutParticipantFields()
          break;
        case 'bill':
          currentField = this.sortoutBillFields()
          break;
        case 'fb':
          // 留言與訂單
          currentField = this.sortoutFbFields()
          break;
        case 'purchaseHeader':
          // 叫貨管理 > 單頭模式
          currentField = this.sortoutPurchaseFields('purchaseHeader')
          break;
        case 'purchaseBody':
          // 叫貨管理 > 單身模式
          currentField = this.sortoutPurchaseFields('purchaseBody')
          break;
        default:
          throw new Error('元件: FieldFilter 內的 checkFieldsData 未處理好');
      }
      this.D4FieldFilter.forEach((d4Field, index) => {
        if (d4Field.selected && currentField[index]) this.selectedFields.push(currentField[index])
      })
      this.fields = currentField
    },
    // 更新偏好
    updatePrefer(currentSelect) {
      console.log(currentSelect)
      let whitchOneTable = this.whitchOneTable
      let item = 'fieldFilter'
      // 額外判斷叫貨單單頭模式
      if (this.whitchOneTable === 'purchaseHeader') {
        whitchOneTable = 'purchaseOrder'
      }
      // 額外判斷叫貨單單身模式
      if (this.whitchOneTable === 'purchaseBody') {
        whitchOneTable = 'purchaseOrder'
        item = 'fieldFilterB'
      }
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: `p_${whitchOneTable}`,
        item: item,
        data: currentSelect.value
      })
    },
    // * 整理欄位
    // 整理商品欄位
    sortoutMerchandiseFields() {
      return [
        {
          index: 'field1',
          name: '多選',
        },
        {
          index: 'field2',
          name: '連結',
        },
        {
          index: 'field3',
          name: '圖示',
        },
        {
          index: 'field4',
          name: '商品',
        },
        {
          index: 'field5',
          name: '分類',
        },
        {
          index: 'field6',
          name: '操作',
        },
        {
          index: 'field7',
          name: '狀態',
        },
        {
          index: 'field8',
          name: '留言',
        },
        {
          index: 'field9',
          name: '訂單',
        },
        {
          index: 'field10',
          name: '未配',
        },
        {
          index: 'field11',
          name: '叫貨',
        },
        {
          index: 'field12',
          name: '庫存',
        },
        {
          index: 'field13',
          name: '供應商',
        },
        {
          index: 'field14',
          name: '時間',
        },
      ]
    },
    // 整理訂單欄位
    sortoutMerchOrderFields() {
      return [
        {
          index: 'field1',
          name: '多選',
        },
        {
          index: 'field2',
          name: '圖示',
        },
        {
          index: 'field3',
          name: '商品',
        },
        {
          index: 'field4',
          name: '顧客姓名',
        },
        {
          index: 'field5',
          name: '操作',
        },
        {
          index: 'field6',
          name: '明細',
        },
        {
          index: 'field7',
          name: '款式',
        },
        {
          index: 'field8',
          name: '金額',
        },
        {
          index: 'field9',
          name: '配單',
        },
        {
          index: 'field10',
          name: '結單',
        },
        {
          index: 'field11',
          name: '收款',
        },
        {
          index: 'field12',
          name: '寄出',
        },
        {
          index: 'field13',
          name: '供應商',
        },
        {
          index: 'field14',
          name: '時間',
        },
        {
          index: 'field15',
          name: '利潤',
        },
      ]
    },
    // 整理庫存欄位
    sortoutStockFields() {
      return [
        {
          index: 'field1',
          name: '商品款式',
        },
        {
          index: 'field2',
          name: '圖示',
        },
        {
          index: 'field3',
          name: '商品',
        },
        {
          index: 'field4',
          name: '操作',
        },
        {
          index: 'field5',
          name: '供應商',
        },
        {
          index: 'field6',
          name: '成本',
        },
        {
          index: 'field7',
          name: '已結未寄',
        },
        {
          index: 'field8',
          name: '倉庫總數',
        },
        {
          index: 'field9',
          name: '已配',
        },
        {
          index: 'field10',
          name: '可配現貨',
        },
        {
          index: 'field11',
          name: '缺貨',
        },
        {
          index: 'field12',
          name: '已叫',
        },
        {
          index: 'field13',
          name: '需叫貨',
        },
        {
          index: 'field14',
          name: '還缺(已付)',
        },
      ]
    },
    // 整理顧客欄位
    sortoutParticipantFields() {
      return [
        {
          index: 'field1',
          name: '多選',
        },
        {
          index: 'field2',
          name: '大頭照',
        },
        {
          index: 'field3',
          name: '狀態',
        },
        {
          index: 'field4',
          name: '顧客',
        },
        {
          index: 'field5',
          name: '顧客群組',
        },
        {
          index: 'field6',
          name: '操作',
        },
        {
          index: 'field7',
          name: '未配',
        },
        {
          index: 'field8',
          name: '已配未結',
        },
        {
          index: 'field9',
          name: '未付款',
        },
        {
          index: 'field10',
          name: '未寄出',
        },
        {
          index: 'field11',
          name: '購物金',
        },
        {
          index: 'field12',
          name: '未結單金額',
        },
        {
          index: 'field13',
          name: '區間金額',
        },
        {
          index: 'field14',
          name: '時間',
        },
      ]
    },
    // 整理帳單欄位
    sortoutBillFields() {
      return [
        {
          index: 'field1',
          name: '多選',
        },
        {
          index: 'field2',
          name: '圖示',
        },
        {
          index: 'field3',
          name: '顧客',
        },
        {
          index: 'field4',
          name: '社群',
        },
        {
          index: 'field5',
          name: '帳單',
        },
        {
          index: 'field6',
          name: '出貨',
        },
        {
          index: 'field7',
          name: '發票',
        },
        {
          index: 'field8',
          name: '付款',
        },
        {
          index: 'field9',
          name: '總計',
        },
        {
          index: 'field10',
          name: '操作',
        }
      ]
    },
    // 整理 FB 留言欄位
    sortoutFbFields() {
      return [
        {
          index: 'field1',
          name: 'FB頭貼',
        },
        {
          index: 'field2',
          name: 'FB名稱',
        },
        {
          index: 'field3',
          name: '留言時間',
        },
        {
          index: 'field4',
          name: '留言內容',
        },
        {
          index: 'field5',
          name: '社群類型',
        },
        {
          index: 'field6',
          name: '狀態',
        },
        {
          index: 'field7',
          name: '操作',
        },
      ]
    },
    // 整理叫貨欄位
    sortoutPurchaseFields(mode) {
      console.log(`mode: ${mode}`)
      if (mode === 'purchaseHeader') {
        return [
          {
            index: 'field1',
            name: '多選',
          },
          {
            index: 'field2',
            name: '叫貨編號',
          },
          {
            index: 'field3',
            name: '叫貨明細',
          },
          {
            index: 'field4',
            name: '供應商',
          },
          {
            index: 'field5',
            name: '備註',
          },
          {
            index: 'field6',
            name: '叫貨時間',
          },
          {
            index: 'field7',
            name: '操作',
          },
        ]
      } else {
        return [
          {
            index: 'field1',
            name: '多選',
          },
          {
            index: 'field2',
            name: '圖片',
          },
          {
            index: 'field3',
            name: '叫貨明細',
          },
          {
            index: 'field4',
            name: '叫貨數量',
          },
          {
            index: 'field5',
            name: '供應商',
          },
          {
            index: 'field6',
            name: '叫貨時間',
          },
          {
            index: 'field7',
            name: '款式',
          },
        ]
      }
    },
  },
}
</script>